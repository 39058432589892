import { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import api from "../../utils/api";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Must be exactly 10 digits")
    .required("Mobile number is required"),
});

const AgentEnrollment = () => {
  useDocumentTitle("Agent Enrollment");

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [apiErrors, setApiErrors] = useState({});

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setApiErrors({});
      const response = await api.post("agent/register", values);
      setSnackbar({
        open: true,
        message: response.data.message,
        severity: "success",
      });
      resetForm();
    } catch (error) {
      const errors = error.response?.data?.errors;

      if (errors) {
        setApiErrors(errors);
        setSnackbar({
          open: true,
          message: "Please correct the errors in the form",
          severity: "error",
        });
      } else {
        setSnackbar({
          open: true,
          message: "Enrollment failed",
          severity: "error",
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "auto", mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h5" gutterBottom>
          Enroll New Agent
        </Typography>
        <Formik
          initialValues={{ name: "", email: "", mobile: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.name &&
                  (Boolean(errors.name) || Boolean(apiErrors.name))
                }
                helperText={(touched.name && errors.name) || apiErrors.name}
                margin="normal"
              />
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.email &&
                  (Boolean(errors.email) || Boolean(apiErrors.email))
                }
                helperText={(touched.email && errors.email) || apiErrors.email}
                margin="normal"
              />
              <TextField
                fullWidth
                id="mobile"
                name="mobile"
                label="Mobile Number"
                value={values.mobile}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.mobile &&
                  (Boolean(errors.mobile) || Boolean(apiErrors.mobile))
                }
                helperText={
                  (touched.mobile && errors.mobile) || apiErrors.mobile
                }
                margin="normal"
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                sx={{ mt: 2 }}
              >
                {isSubmitting ? "Enrolling..." : "Enroll Agent"}
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AgentEnrollment;
