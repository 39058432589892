import { useState, useRef } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  LinearProgress,
  Alert,
  AlertTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Link as MuiLink,
  Snackbar,
} from "@mui/material";
import {
  CloudUpload,
  Download,
  CheckCircleOutline,
  ErrorOutline,
} from "@mui/icons-material";
import api from "../../utils/api";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import DownloadIcon from "@mui/icons-material/Download";

const LeadUpload = () => {
  useDocumentTitle("Upload Leads");
  const fileInputRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState({
    total: 0,
    current: 0,
    success: 0,
    failed: 0,
    skipped: 0,
  });
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const processCSV = async (file) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const text = event.target.result;
        const allRows = text
          .split(/\r?\n/)
          .map((row) => row.trim())
          .filter((row) => row.length > 0);

        // Split and take only first 18 columns (0-17) for headers
        const headerColumns = allRows[0].split(",").slice(0, 18);
        const headers = [...headerColumns, "UPLOAD_STATUS"].join(",");

        // Split and take only first 18 columns (0-17) for mandatory row
        const mandatoryColumns = allRows[1].split(",").slice(0, 18);
        const mandatoryRow = [...mandatoryColumns, "Status of upload"].join(
          ","
        );

        const dataRows = allRows.slice(2);
        let resultRows = [headers, mandatoryRow];
        let hasAnyError = false;

        setProgress({
          total: dataRows.length,
          current: 0,
          success: 0,
          failed: 0,
          skipped: 0,
        });
        setUploading(true);
        setError(null);

        for (let i = 0; i < dataRows.length; i++) {
          // Split the row and take only first 18 columns (0-17)
          const columns = dataRows[i]
            .split(",")
            .map((col) => col.replace(/^["']|["']$/g, "").trim());

          // Take only the first 18 columns
          const relevantColumns = columns.slice(0, 18);

          // Check if this record was already uploaded successfully
          const existingStatus = columns[18]?.toLowerCase();
          if (existingStatus === "yes") {
            resultRows.push([...relevantColumns, "yes"].join(","));
            setProgress((prev) => ({
              ...prev,
              current: i + 1,
              skipped: prev.skipped + 1,
            }));
            continue;
          }

          let uploadStatus = "Failed";
          let errorMessage = "";

          try {
            // Validate required fields
            if (!columns[0] || !columns[2]) {
              // name and mobile are mandatory
              setError(
                `Row ${i + 3}: Missing required fields (Name or Mobile)`
              );
              break;
            }

            const leadData = {
              name: columns[0], // NAME
              business_name: columns[1] || "", // BUSINESS NAME
              phone_number: columns[2], // MOBILE
              email: columns[3] || "", // EMAIL
              address_line1: columns[4] || "", // ADDRESS LINE 1
              address_line2: columns[5] || "", // ADDRESS LINE 2
              address_area: columns[6] || "", // ADDRESS AREA
              address_nearby: columns[7] || "", // ADDRESS NEARBY
              address_district: columns[8] || "", // ADDRESS DISTRICT
              address_city: columns[9] || "", // ADDRESS CITY
              address_state: columns[10] || "", // ADDRESS STATE
              pincode: columns[11] || "", // PINCODE
              pan_number: columns[12] || "", // PAN
              aadhaar_number: columns[13] || "", // AADHAAR
              occupation: columns[14] || "", // OCCUPATION
              monthly_income: columns[15] ? parseInt(columns[15], 10) : 0, // MONTHLY INCOME
              itr_filing_status: columns[16] || "", // ITR FILING
              loan_type: columns[17] || "", // LOAN TYPE
            };

            console.log("Payload for row:", leadData);

            const response = await api.post("/agent/lead/create", leadData);
            uploadStatus = "Success";

            setProgress((prev) => ({
              ...prev,
              current: i + 1,
              success: prev.success + 1,
            }));
          } catch (error) {
            hasAnyError = true;
            // Extract error messages from the response
            if (error.response?.data?.errors) {
              const errorMessages = Object.entries(error.response.data.errors)
                .map(([field, message]) => `${field}: ${message}`)
                .join("; ");
              errorMessage = errorMessages;
            } else {
              errorMessage =
                error.response?.data?.message ||
                error.message ||
                "Unknown error";
            }

            setProgress((prev) => ({
              ...prev,
              current: i + 1,
              failed: prev.failed + 1,
            }));
          }

          // Add the row with status to results, using only the first 18 columns
          const statusMessage =
            uploadStatus === "Success" ? "Yes" : `No - ${errorMessage}`;
          resultRows.push([...relevantColumns, statusMessage].join(","));
        }

        // Create and download the result file
        const resultContent = resultRows.join("\n");
        const blob = new Blob([resultContent], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `upload_results_${new Date().getTime()}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        // Show appropriate message based on overall success/failure
        if (!hasAnyError) {
          setSnackbar({
            open: true,
            message: "All records were uploaded successfully!",
            severity: "success",
          });
        } else if (progress.success > 0) {
          setSnackbar({
            open: true,
            message: `Upload completed with ${progress.success} successful and ${progress.failed} failed records. Please check the downloaded file for details.`,
            severity: "warning",
          });
        } else {
          setSnackbar({
            open: true,
            message:
              "Failed to upload any records. Please check the downloaded file for details.",
            severity: "error",
          });
        }
      } catch (error) {
        console.error("CSV Processing Error:", error);
        setError("Failed to process CSV file: " + error.message);
        setSnackbar({
          open: true,
          message: "Failed to process CSV file. Please try again.",
          severity: "error",
        });
      } finally {
        setUploading(false);
        fileInputRef.current.value = "";
      }
    };

    reader.onerror = () => {
      setError("Failed to read the file");
      setUploading(false);
      fileInputRef.current.value = "";
      setSnackbar({
        open: true,
        message: "Failed to read the file. Please try again.",
        severity: "error",
      });
    };

    reader.readAsText(file);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      setError(null);
      processCSV(file);
    } else {
      setError("Please upload a valid CSV file");
    }
    fileInputRef.current.value = "";
  };

  const resetUploadStates = () => {
    setError(null);
    setUploading(false);
    setProgress({
      total: 0,
      current: 0,
      success: 0,
      failed: 0,
      skipped: 0,
    });
    fileInputRef.current.value = "";
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Upload Customer Leads
      </Typography>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Instructions
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <Download color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Step 1: Download the Template"
              secondary={
                <>
                  Download our standard CSV template using the button below
                  <Box sx={{ mt: 1 }}>
                    <Button
                      component="a"
                      href="/Das Financial Services Leads Upload Template.csv"
                      download
                      variant="outlined"
                      size="small"
                      startIcon={<DownloadIcon />}
                      sx={{ textTransform: "none" }}
                    >
                      Download Template File
                    </Button>
                  </Box>
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutline color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Step 2: Fill the Template"
              secondary="Fill the data from row 3 onwards. Do not modify the header (row 1) and help text (row 2)"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CloudUpload color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Step 3: Upload the File"
              secondary="Upload the filled CSV file to process the leads"
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 2 }} />

        <input
          type="file"
          accept=".csv"
          hidden
          ref={fileInputRef}
          onChange={handleFileUpload}
        />
        <Button
          variant="contained"
          startIcon={<CloudUpload />}
          onClick={() => {
            resetUploadStates();
            fileInputRef.current.click();
          }}
          disabled={uploading}
        >
          Upload CSV File
        </Button>
      </Paper>

      {progress.total > 0 && (
        <Paper sx={{ p: 3, mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Upload Progress
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(progress.current / progress.total) * 100}
            sx={{ mb: 2 }}
          />
          <Typography variant="body2" color="text.secondary">
            Processed: {progress.current} of {progress.total} leads
          </Typography>
          <Typography variant="body2" color="success.main">
            Successfully uploaded: {progress.success} new leads
          </Typography>
          {progress.skipped > 0 && (
            <Typography variant="body2" color="info.main">
              Previously uploaded: {progress.skipped} leads
            </Typography>
          )}
          {progress.failed > 0 && (
            <Typography variant="body2" color="error">
              Failed uploads: {progress.failed} leads
            </Typography>
          )}
        </Paper>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LeadUpload;
