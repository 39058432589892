import { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Alert,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import api from "../utils/api";
import useDocumentTitle from "../hooks/useDocumentTitle";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const StyledLink = {
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
};

const ForgotPassword = () => {
  useDocumentTitle("Forgot Password");
  const [status, setStatus] = useState({ type: "", message: "" });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await api.post("agent/send-password-reset-link", {
        email: values.email,
      });

      setStatus({
        type: "success",
        message:
          response.data.message ||
          "Password reset link has been sent to your email",
      });
    } catch (err) {
      setStatus({
        type: "error",
        message:
          err.response?.data?.error ||
          err.response?.data?.message ||
          "Failed to send reset link. Please try again.",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "#f5f5f5",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          width: "100%",
          maxWidth: 400,
        }}
      >
        <Typography variant="h5" align="center" mb={3}>
          Forgot Password
        </Typography>
        <Typography
          variant="body2"
          align="center"
          mb={3}
          color="text.secondary"
        >
          Enter your email address and we&apos;ll send you a link to reset your
          password.
        </Typography>

        {status.message && (
          <Alert severity={status.type} sx={{ mb: 2 }}>
            {status.message}
          </Alert>
        )}

        <Formik
          initialValues={{ email: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                margin="normal"
              />

              <Button
                fullWidth
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                sx={{ mt: 3, mb: 2 }}
              >
                {isSubmitting ? "Sending..." : "Send Reset Link"}
              </Button>

              <Box textAlign="center">
                <Link
                  component={RouterLink}
                  to="/login"
                  variant="body2"
                  sx={StyledLink}
                >
                  Back to Login
                </Link>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default ForgotPassword;
