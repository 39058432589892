import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  Avatar,
  Divider,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import api from "../../utils/api";
import { Edit, Save, Lock, PhotoCamera } from "@mui/icons-material";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

const getCroppedImg = async (imageSrc, pixelCrop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Canvas to Blob conversion failed"));
        }
      },
      "image/jpeg",
      0.95
    );
  });
};

const ProfileManagement = () => {
  useDocumentTitle("Profile Management");
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [emailOtpDialog, setEmailOtpDialog] = useState(false);
  const [mobileOtpDialog, setMobileOtpDialog] = useState(false);
  const [otp, setOtp] = useState("");
  const [verifyingOtp, setVerifyingOtp] = useState(false);
  const [resendingOtp, setResendingOtp] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "info",
  });
  const [verificationType, setVerificationType] = useState(null); // 'email' or 'mobile'

  const [profile, setProfile] = useState({
    name: "",
    email: "",
    mobile: "",
    role: "",
    lastLogin: "",
    lastLoginIp: "",
    createdAt: "",
    agentId: "",
  });

  const [editedProfile, setEditedProfile] = useState({});

  const [profilePhoto, setProfilePhoto] = useState(null);

  const [cropperOpen, setCropperOpen] = useState(false);
  const [tempImage, setTempImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const fileInputRef = useRef(null);

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date
      .toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace(",", "");
  };

  const fetchProfile = async () => {
    try {
      setLoading(true);
      const response = await api.get("agent/profile/get");
      const profileData = {
        name: response.data.data.name,
        email: response.data.data.email,
        mobile: response.data.data.mobile,
        role: response.data.data.role,
        lastLogin: response.data.data.last_login_at,
        lastLoginIp: response.data.data.last_login_ip,
        createdAt: response.data.data.created_at,
        agentId: response.data.data.agent_id,
      };
      setProfile(profileData);
      setEditedProfile(profileData);

      // Fetch profile photo
      try {
        const photoResponse = await api.get(
          `agent/profile/photo/${response.data.data.agent_id}`
        );
        if (
          photoResponse.data.status === "success" &&
          photoResponse.data.data.image
        ) {
          setProfilePhoto(photoResponse.data.data.image);
        } else {
          setProfilePhoto(null);
        }
      } catch {
        // Photo not found - using default avatar
        setProfilePhoto(null);
      }
    } catch {
      setAlert({
        show: true,
        message: "Failed to load profile data",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleChange = (e) => {
    setEditedProfile({
      ...editedProfile,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      const response = await api.put("agent/profile/update", {
        name: editedProfile.name,
        email: editedProfile.email,
        mobile: editedProfile.mobile,
      });

      if (
        response.data.verification_required.email &&
        response.data.verification_required.mobile
      ) {
        // Both email and mobile need verification
        setVerificationType("email"); // Start with email verification
        setEmailOtpDialog(true);
      } else if (response.data.verification_required.email) {
        // Only email needs verification
        setVerificationType("email");
        setEmailOtpDialog(true);
      } else if (response.data.verification_required.mobile) {
        // Only mobile needs verification
        setVerificationType("mobile");
        setMobileOtpDialog(true);
      } else {
        // No verification needed
        setProfile(editedProfile);
        setEditMode(false);
        setAlert({
          show: true,
          message: "Profile updated successfully",
          severity: "success",
        });
      }
    } catch (error) {
      let errorMessage = "Failed to update profile";

      if (error.response?.data?.errors) {
        // Handle validation errors
        const errors = error.response.data.errors;
        if (errors.name) {
          errorMessage =
            "Name should only contain letters and spaces, maximum 100 characters";
        } else if (errors.email) {
          errorMessage = "Please provide a valid email address";
        } else if (errors.mobile) {
          errorMessage = "Please provide a valid 10-digit mobile number";
        } else {
          errorMessage = Object.values(errors)[0];
        }
      } else if (error.response?.data?.message) {
        // Handle duplicate email/mobile errors
        errorMessage = error.response.data.message;
      }

      setAlert({
        show: true,
        message: errorMessage,
        severity: "error",
      });
    } finally {
      setSaving(false);
    }
  };

  // Handle Email OTP Verification
  const handleEmailOtpVerification = async () => {
    try {
      setVerifyingOtp(true);
      await api.post("agent/profile/verify-email-otp", {
        otp: otp,
        email: editedProfile.email,
      });

      setEmailOtpDialog(false);
      if (editedProfile.mobile !== profile.mobile) {
        // If mobile also needs verification, show mobile OTP dialog
        setVerificationType("mobile");
        setMobileOtpDialog(true);
      } else {
        // Only email needed verification
        setProfile(editedProfile);
        setEditMode(false);
      }
      setAlert({
        show: true,
        message: "Email verified successfully",
        severity: "success",
      });
    } catch (error) {
      setAlert({
        show: true,
        message: error.response?.data?.message || "Failed to verify email OTP",
        severity: "error",
      });
    } finally {
      setVerifyingOtp(false);
      setOtp("");
    }
  };

  // Handle Mobile OTP Verification
  const handleMobileOtpVerification = async () => {
    try {
      setVerifyingOtp(true);
      await api.post("agent/profile/verify-mobile-otp", {
        otp: otp,
        mobile: editedProfile.mobile,
      });

      setMobileOtpDialog(false);
      setProfile(editedProfile);
      setEditMode(false);
      setAlert({
        show: true,
        message: "Mobile number verified successfully",
        severity: "success",
      });
    } catch (error) {
      setAlert({
        show: true,
        message: error.response?.data?.message || "Failed to verify mobile OTP",
        severity: "error",
      });
    } finally {
      setVerifyingOtp(false);
      setOtp("");
    }
  };

  // Handle Resend OTP (works for both email and mobile)
  const handleResendOtp = async () => {
    try {
      setResendingOtp(true);
      const endpoint =
        verificationType === "email"
          ? "agent/profile/resend-email-otp"
          : "agent/profile/resend-mobile-otp";

      const payload =
        verificationType === "email"
          ? { email: editedProfile.email }
          : { mobile: editedProfile.mobile };

      await api.post(endpoint, payload);

      // Start resend timer (60 seconds)
      setResendTimer(60);
      const timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      setAlert({
        show: true,
        message: `OTP sent successfully to your ${verificationType}`,
        severity: "success",
      });
    } catch (error) {
      setAlert({
        show: true,
        message: error.response?.data?.message || "Failed to resend OTP",
        severity: "error",
      });
    } finally {
      setResendingOtp(false);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await api.post("agent/send-password-reset-link", {
        email: profile.email,
      });
      setAlert({
        show: true,
        message: "Password reset link sent to your email",
        severity: "success",
      });
    } catch {
      setAlert({
        show: true,
        message: "Failed to send password reset link",
        severity: "error",
      });
    }
  };

  const handlePhotoClick = () => {
    fileInputRef.current.click();
  };

  const validateImage = (file) => {
    return new Promise((resolve, reject) => {
      if (!file || !(file instanceof File)) {
        reject("Invalid file object");
        return;
      }

      // Check file type
      if (!file.type.match(/^image\/(jpeg|png|gif)$/)) {
        reject("Only JPEG, PNG and GIF images are allowed");
        return;
      }

      // Check file size (1MB = 1048576 bytes)
      if (file.size > 1048576) {
        reject("Profile photo must not exceed 1MB");
        return;
      }

      // Check dimensions
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      img.onload = () => {
        URL.revokeObjectURL(objectUrl);
        if (img.width < 100 || img.height < 100) {
          reject("Image dimensions must be at least 100x100 pixels");
        } else if (img.width > 2000 || img.height > 2000) {
          reject("Image dimensions must not exceed 2000x2000 pixels");
        } else {
          resolve(true);
        }
      };

      img.onerror = () => {
        URL.revokeObjectURL(objectUrl);
        reject("Invalid image file");
      };

      img.src = objectUrl;
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      await validateImage(file);
      const reader = new FileReader();
      reader.onload = () => {
        setTempImage(reader.result);
        setCropperOpen(true);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      setAlert({
        show: true,
        message: error,
        severity: "error",
      });
    }
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleUploadPhoto = async () => {
    try {
      const croppedImageBlob = await getCroppedImg(
        tempImage,
        croppedAreaPixels
      );

      // Convert Blob to Base64
      const base64Image = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(croppedImageBlob);
      });

      const response = await api.post(
        "agent/profile/photo/upload",
        { image: base64Image },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Refresh profile photo
      fetchProfile();

      setCropperOpen(false);
      setAlert({
        show: true,
        message: response.data.message || "Profile photo updated successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Upload error:", error);
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data?.errors?.[0] ||
        "Failed to upload photo";
      setAlert({
        show: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  // Add validation before allowing save
  const validateForm = () => {
    const nameRegex = /^[A-Za-z\s]{1,100}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^\d{10}$/;

    if (!nameRegex.test(editedProfile.name)) {
      setAlert({
        show: true,
        message:
          "Name should only contain letters and spaces, maximum 100 characters",
        severity: "error",
      });
      return false;
    }

    if (!emailRegex.test(editedProfile.email)) {
      setAlert({
        show: true,
        message: "Please provide a valid email address",
        severity: "error",
      });
      return false;
    }

    if (!mobileRegex.test(editedProfile.mobile)) {
      setAlert({
        show: true,
        message: "Please provide a valid 10-digit mobile number",
        severity: "error",
      });
      return false;
    }

    return true;
  };

  // Update the save button click handler
  const handleSaveClick = () => {
    if (validateForm()) {
      handleSave();
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {alert.show && (
        <Alert
          severity={alert.severity}
          sx={{ mb: 2 }}
          onClose={() => setAlert({ ...alert, show: false })}
        >
          {alert.message}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Profile Overview */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  position: "relative",
                  width: 100,
                  margin: "0 auto",
                  "&:hover .camera-icon": {
                    // Move hover effect to parent Box
                    opacity: 1,
                  },
                }}
              >
                <Avatar
                  sx={{
                    width: 100,
                    height: 100,
                    marginBottom: 2,
                    bgcolor: "primary.main",
                    cursor: "pointer",
                  }}
                  src={profilePhoto}
                  onClick={handlePhotoClick}
                >
                  {profile.name?.[0]}
                </Avatar>
                <input
                  type="file"
                  hidden
                  ref={fileInputRef}
                  accept="image/jpeg,image/png,image/gif"
                  onChange={handleFileChange}
                />
                <PhotoCamera
                  className="camera-icon"
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    opacity: 0,
                    transition: "opacity 0.3s",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    borderRadius: "50%",
                    padding: "8px",
                    color: "white",
                    pointerEvents: "none",
                    zIndex: 1,
                  }}
                />
              </Box>
              <Typography variant="h5" gutterBottom>
                {profile.name}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                {profile.role === "A" ? "Admin" : "Agent"}
              </Typography>
              <Button
                variant="outlined"
                startIcon={<Lock />}
                onClick={handlePasswordReset}
                sx={{ mt: 2 }}
              >
                Reset Password
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Profile Details */}
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
              >
                <Typography variant="h6">Profile Details</Typography>
                {!editMode ? (
                  <Button startIcon={<Edit />} onClick={handleEdit}>
                    Edit Profile
                  </Button>
                ) : (
                  <Button
                    startIcon={
                      saving ? <CircularProgress size={20} /> : <Save />
                    }
                    onClick={handleSaveClick}
                    disabled={saving}
                    variant="contained"
                  >
                    {saving ? "Saving..." : "Save Changes"}
                  </Button>
                )}
              </Box>
              <Divider sx={{ mb: 3 }} />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={editMode ? editedProfile.name : profile.name}
                    onChange={handleChange}
                    disabled={!editMode}
                    error={
                      editMode &&
                      !editedProfile.name.match(/^[A-Za-z\s]{1,100}$/)
                    }
                    helperText={
                      editMode &&
                      !editedProfile.name.match(/^[A-Za-z\s]{1,100}$/)
                        ? "Name should only contain letters and spaces"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={editMode ? editedProfile.email : profile.email}
                    onChange={handleChange}
                    disabled={!editMode}
                    error={
                      editMode &&
                      !editedProfile.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
                    }
                    helperText={
                      editMode &&
                      !editedProfile.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
                        ? "Please enter a valid email address"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Mobile"
                    name="mobile"
                    value={editMode ? editedProfile.mobile : profile.mobile}
                    onChange={handleChange}
                    disabled={!editMode}
                    error={editMode && !editedProfile.mobile.match(/^\d{10}$/)}
                    helperText={
                      editMode && !editedProfile.mobile.match(/^\d{10}$/)
                        ? "Please enter a valid 10-digit mobile number"
                        : ""
                    }
                  />
                </Grid>
              </Grid>

              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle2" color="textSecondary">
                  Last Login: {formatDateTime(profile.lastLogin)}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  Last Login IP: {profile.lastLoginIp}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  Member Since: {formatDateTime(profile.createdAt)}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Email OTP Dialog */}
      <Dialog
        open={emailOtpDialog}
        onClose={() => !verifyingOtp && setEmailOtpDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Verify Email OTP</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              autoFocus
              fullWidth
              label="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              disabled={verifyingOtp}
              placeholder="Enter 6-digit OTP"
              inputProps={{ maxLength: 6 }}
            />
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ mt: 1, display: "block" }}
            >
              OTP has been sent to {editedProfile.email}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Box sx={{ width: "100%" }}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleEmailOtpVerification}
              disabled={verifyingOtp || otp.length !== 6}
              sx={{ mb: 1 }}
            >
              {verifyingOtp ? <CircularProgress size={24} /> : "Verify OTP"}
            </Button>

            <Button
              fullWidth
              color="secondary"
              onClick={handleResendOtp}
              disabled={resendingOtp || resendTimer > 0}
            >
              {resendTimer > 0 ? (
                `Resend OTP in ${resendTimer}s`
              ) : resendingOtp ? (
                <CircularProgress size={24} />
              ) : (
                "Resend OTP"
              )}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Mobile OTP Dialog */}
      <Dialog
        open={mobileOtpDialog}
        onClose={() => !verifyingOtp && setMobileOtpDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Verify Mobile OTP</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              autoFocus
              fullWidth
              label="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              disabled={verifyingOtp}
              placeholder="Enter 6-digit OTP"
              inputProps={{ maxLength: 6 }}
            />
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ mt: 1, display: "block" }}
            >
              OTP has been sent to {editedProfile.mobile}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Box sx={{ width: "100%" }}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleMobileOtpVerification}
              disabled={verifyingOtp || otp.length !== 6}
              sx={{ mb: 1 }}
            >
              {verifyingOtp ? <CircularProgress size={24} /> : "Verify OTP"}
            </Button>

            <Button
              fullWidth
              color="secondary"
              onClick={handleResendOtp}
              disabled={resendingOtp || resendTimer > 0}
            >
              {resendTimer > 0 ? (
                `Resend OTP in ${resendTimer}s`
              ) : resendingOtp ? (
                <CircularProgress size={24} />
              ) : (
                "Resend OTP"
              )}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Image Cropper Dialog */}
      <Dialog
        open={cropperOpen}
        onClose={() => setCropperOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Crop Profile Photo</DialogTitle>
        <DialogContent>
          <Box sx={{ position: "relative", height: 400, mb: 2 }}>
            <Cropper
              image={tempImage}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={handleCropComplete}
            />
          </Box>
          <Box sx={{ px: 2 }}>
            <Typography>Zoom</Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e, value) => setZoom(value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCropperOpen(false)}>Cancel</Button>
          <Button onClick={handleUploadPhoto} variant="contained">
            Update Photo
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProfileManagement;
