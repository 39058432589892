import {
  Box,
  Card,
  CardContent,
  Typography,
  Switch,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Alert,
  Grid,
} from "@mui/material";
import { useState, useEffect } from "react";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import api from "../../utils/api";
import { Notifications, Language, Save } from "@mui/icons-material";
import { useTheme } from "../../contexts/ThemeContext";

const Settings = () => {
  useDocumentTitle("Settings");
  const [settings, setSettings] = useState({
    notifications: {
      email: true,
      sms: true,
      leadAssignment: true,
      statusUpdates: true,
    },
    preferences: {
      language: "en",
      theme: "light",
      timezone: "UTC",
    },
  });
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "success",
  });
  const { updateTheme } = useTheme();
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const fetchSettings = async () => {
    try {
      const response = await api.get("agent/profile/get");
      const { data } = response.data;

      setSettings({
        notifications: {
          email: data.notify_email === "1",
          sms: data.notify_sms === "1",
          leadAssignment: data.notify_lead_assignment === "1",
          statusUpdates: data.notify_status_update === "1",
        },
        preferences: {
          language: data.language,
          theme: data.theme,
          timezone: data.timezone,
        },
      });
    } catch {
      setAlert({
        show: true,
        message: "Failed to load settings",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleNotificationChange = (setting) => {
    setSettings((prev) => ({
      ...prev,
      notifications: {
        ...prev.notifications,
        [setting]: !prev.notifications[setting],
      },
    }));
  };

  const handlePreferenceChange = (setting, value) => {
    setUnsavedChanges(true);
    setSettings((prev) => ({
      ...prev,
      preferences: {
        ...prev.preferences,
        [setting]: value,
      },
    }));
  };

  const saveNotificationSettings = async () => {
    try {
      const notificationData = {
        notify_email: settings.notifications.email ? 1 : 0,
        notify_sms: settings.notifications.sms ? 1 : 0,
        notify_lead_assignment: settings.notifications.leadAssignment ? 1 : 0,
        notify_status_update: settings.notifications.statusUpdates ? 1 : 0,
      };

      const response = await api.put(
        "agent/profile/notifications",
        notificationData
      );
      setAlert({
        show: true,
        message: response.data.message,
        severity: "success",
      });
    } catch (error) {
      const errorMessage = error.response?.data?.errors
        ? Object.values(error.response.data.errors)[0]
        : "Failed to update notification settings";

      setAlert({
        show: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  const savePreferenceSettings = async () => {
    try {
      const preferenceData = {
        language: settings.preferences.language,
        theme: settings.preferences.theme,
        timezone: settings.preferences.timezone,
      };

      const response = await api.put(
        "agent/profile/preferences",
        preferenceData
      );

      updateTheme(settings.preferences.theme);
      setUnsavedChanges(false);

      setAlert({
        show: true,
        message: response.data.message,
        severity: "success",
      });
    } catch (error) {
      let errorMessage = "Failed to update preferences";

      if (error.response?.data?.errors) {
        const errors = error.response.data.errors;
        if (errors.language) {
          errorMessage =
            "Invalid language selection. Please choose either English or Hindi.";
        } else if (errors.theme) {
          errorMessage =
            "Invalid theme selection. Please choose Light, Dark, or System.";
        } else if (errors.timezone) {
          errorMessage =
            "Invalid timezone selection. Please choose a valid timezone.";
        } else {
          errorMessage = Object.values(errors)[0];
        }
      } else if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }

      setAlert({
        show: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  const saveSettings = async () => {
    try {
      await Promise.all([saveNotificationSettings(), savePreferenceSettings()]);
    } catch {
      // Error handling is done in individual save functions
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (unsavedChanges) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [unsavedChanges]);

  return (
    <Box sx={{ p: 3 }}>
      {alert.show && (
        <Alert
          severity={alert.severity}
          sx={{ mb: 2 }}
          onClose={() => setAlert({ ...alert, show: false })}
        >
          {alert.message}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Notification Settings */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Notifications sx={{ mr: 1 }} />
                <Typography variant="h6">Notification Settings</Typography>
              </Box>
              <List>
                <ListItem>
                  <ListItemText primary="Email Notifications" />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      checked={settings.notifications.email}
                      onChange={() => handleNotificationChange("email")}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="SMS Notifications" />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      checked={settings.notifications.sms}
                      onChange={() => handleNotificationChange("sms")}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Lead Assignment Notifications"
                    secondary="Get notified when new leads are assigned"
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      checked={settings.notifications.leadAssignment}
                      onChange={() =>
                        handleNotificationChange("leadAssignment")
                      }
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Status Update Notifications"
                    secondary="Get notified when lead status changes"
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      checked={settings.notifications.statusUpdates}
                      onChange={() => handleNotificationChange("statusUpdates")}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Preferences */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Language sx={{ mr: 1 }} />
                <Typography variant="h6">Preferences</Typography>
              </Box>
              <Box sx={{ mb: 3 }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Language</InputLabel>
                  <Select
                    value={settings.preferences.language}
                    onChange={(e) =>
                      handlePreferenceChange("language", e.target.value)
                    }
                    label="Language"
                  >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="hi">Hindi</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Theme</InputLabel>
                  <Select
                    value={settings.preferences.theme}
                    onChange={(e) =>
                      handlePreferenceChange("theme", e.target.value)
                    }
                    label="Theme"
                  >
                    <MenuItem value="light">Light</MenuItem>
                    <MenuItem value="dark">Dark</MenuItem>
                    <MenuItem value="system">System Default</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>Timezone</InputLabel>
                  <Select
                    value={settings.preferences.timezone}
                    onChange={(e) =>
                      handlePreferenceChange("timezone", e.target.value)
                    }
                    label="Timezone"
                  >
                    <MenuItem value="UTC">UTC</MenuItem>
                    <MenuItem value="America/New_York">
                      Eastern Time (ET)
                    </MenuItem>
                    <MenuItem value="America/Los_Angeles">
                      Pacific Time (PT)
                    </MenuItem>
                    <MenuItem value="Asia/Kolkata">
                      India Standard Time (IST)
                    </MenuItem>
                    <MenuItem value="Europe/London">
                      British Time (GMT/BST)
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
        {unsavedChanges && (
          <Typography color="warning.main" sx={{ mr: 2, alignSelf: "center" }}>
            You have unsaved changes
          </Typography>
        )}
        <Button
          variant="contained"
          startIcon={<Save />}
          onClick={saveSettings}
          disabled={!unsavedChanges}
        >
          Save Settings
        </Button>
      </Box>
    </Box>
  );
};

export default Settings;
