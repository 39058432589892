import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  IconButton,
} from "@mui/material";
import {
  DownloadOutlined,
  Refresh,
  TrendingUp,
  Assessment,
  Speed,
} from "@mui/icons-material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import api from "../../utils/api";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useMediaQuery, useTheme } from "@mui/material";

const Reports = () => {
  useDocumentTitle("Reports");
  const [loading, setLoading] = useState(true);
  const [timeRange, setTimeRange] = useState("7"); // Default to 7 days
  const [reportData, setReportData] = useState({
    summary: {
      totalLeads: 0,
      convertedLeads: 0,
      conversionRate: 0,
      avgProcessingTime: 0,
    },
    agentPerformance: [],
    leadTrends: [],
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchReportData = useCallback(async () => {
    try {
      setLoading(true);
      console.log("Fetching report data..."); // Debug log
      const response = await api.get(`agent/reports/data?days=${timeRange}`);
      console.log("Report data response:", response.data); // Debug log
      setReportData(response.data.data);
    } catch (error) {
      console.error("Failed to fetch report data:", error);
    } finally {
      setLoading(false);
    }
  }, [timeRange]);

  useEffect(() => {
    fetchReportData();
  }, [fetchReportData]);

  const handleExportReport = async () => {
    try {
      const response = await api.get(`agent/reports/export?days=${timeRange}`, {
        responseType: "text",
      });

      const blob = new Blob([response.data], {
        type: "text/csv;charset=utf-8;",
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      const currentDate = new Date().toISOString().split("T")[0];
      link.setAttribute("download", `report-${currentDate}.csv`);

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.error("Failed to export report:", error);
    }
  };

  const summaryCards = [
    {
      title: "Total Leads",
      value: reportData.summary.totalLeads,
      icon: <Assessment color="primary" />,
    },
    {
      title: "Converted Leads",
      value: reportData.summary.convertedLeads,
      icon: <TrendingUp color="success" />,
    },
    {
      title: "Conversion Rate",
      value: `${reportData.summary.conversionRate}%`,
      icon: <Speed color="info" />,
    },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" sx={{ mb: { xs: 2, sm: 0 } }}>
          Reports & Analytics
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
            mt: { xs: 2, sm: 0 },
            alignItems: { xs: "stretch", sm: "center" },
            justifyContent: { sm: "flex-end" },
          }}
        >
          <FormControl
            sx={{
              minWidth: { xs: "100%", sm: 120 },
              "& .MuiOutlinedInput-root": {
                backgroundColor: "background.paper",
                color: "text.primary",
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.main",
                },
              },
              "& .MuiInputLabel-root": {
                color: "text.secondary",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "divider",
              },
              "& .MuiSelect-icon": {
                color: "text.secondary",
              },
            }}
          >
            <InputLabel>Time Range</InputLabel>
            <Select
              value={timeRange}
              label="Time Range"
              onChange={(e) => setTimeRange(e.target.value)}
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: "background.paper",
                    "& .MuiMenuItem-root": {
                      color: "text.primary",
                    },
                  },
                },
              }}
            >
              <MenuItem value="7">Last 7 Days</MenuItem>
              <MenuItem value="30">Last 30 Days</MenuItem>
              <MenuItem value="90">Last 90 Days</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            fullWidth={isMobile}
            startIcon={<DownloadOutlined />}
            onClick={handleExportReport}
          >
            Export CSV
          </Button>

          <IconButton
            onClick={fetchReportData}
            disabled={loading}
            sx={{ alignSelf: { xs: "center", sm: "auto" } }}
          >
            <Refresh />
          </IconButton>
        </Box>
      </Box>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {/* Summary Cards */}
          {summaryCards.map((card, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card>
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    {card.icon}
                    <Typography variant="h6" sx={{ ml: 1 }}>
                      {card.title}
                    </Typography>
                  </Box>
                  <Typography variant="h3">{card.value}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}

          {/* Lead Trends Chart */}
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Lead Trends" />
              <CardContent sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={reportData.leadTrends}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="total"
                      stroke="#8884d8"
                      name="Total Leads"
                    />
                    <Line
                      type="monotone"
                      dataKey="converted"
                      stroke="#82ca9d"
                      name="Converted"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>

          {/* Agent Performance Table */}
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Agent Performance" />
              <CardContent>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Agent Name</TableCell>
                        <TableCell>Assigned Leads</TableCell>
                        <TableCell>Processed Leads</TableCell>
                        <TableCell>Conversion Rate</TableCell>
                        <TableCell>Avg. Processing Time</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportData.agentPerformance
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((agent) => (
                          <TableRow key={agent.agent_id}>
                            <TableCell>{agent.name}</TableCell>
                            <TableCell>{agent.assigned_leads}</TableCell>
                            <TableCell>{agent.processed_leads}</TableCell>
                            <TableCell>{agent.conversion_rate}%</TableCell>
                            <TableCell>{agent.avg_processing_time}h</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    count={reportData.agentPerformance.length}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(e) => {
                      setRowsPerPage(parseInt(e.target.value, 10));
                      setPage(0);
                    }}
                  />
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Reports;
