import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import api from "../../utils/api";

const LOAN_TYPES = [
  "Home",
  "Personal",
  "Auto",
  "Student",
  "Business",
  "Debt Consolidation",
  "Agriculture",
];

const OCCUPATION_TYPES = [
  "Service / Salaried",
  "Business / Entrepreneurship",
  "Freelancer / Consultant",
  "Student",
  "Retired",
  "Unemployed",
  "Nonprofit / NGO",
  "Homemaker",
  "Self-Employed",
  "Government / Public Sector",
  "Agriculture / Farming",
];

const EditLead = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    email: "",
    address_line1: "",
    address_line2: "",
    address_area: "",
    address_nearby: "",
    address_district: "",
    address_city: "",
    address_state: "",
    address_country: "IN",
    pincode: "",
    pan_number: "",
    aadhaar_number: "",
    occupation: "",
    monthly_income: "",
    itr_filing_status: "",
    loan_type: "",
  });

  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  // Add new state for pincode validation
  const [validatingPincode, setValidatingPincode] = useState(false);

  // Add a new state to track the initial pincode
  const [initialPincode, setInitialPincode] = useState("");

  // Validation functions
  const validateField = async (name, value, isSubmitting = false) => {
    let error = "";

    switch (name) {
      case "name":
        if (value && (!/^[a-zA-Z\s]*$/.test(value) || value.length > 100)) {
          error =
            "Name should only contain letters and spaces (max 100 characters)";
        }
        break;

      case "phone_number":
        if (value && !/^[6-9]\d{9}$/.test(value)) {
          error = "Enter valid 10-digit mobile number";
        }
        break;

      case "email":
        if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = "Enter valid email address";
        }
        break;

      case "pan_number":
        if (value && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value)) {
          error = "Enter valid PAN number";
        }
        break;

      case "aadhaar_number":
        if (value && !/^\d{12}$/.test(value)) {
          error = "Enter valid 12-digit Aadhaar number";
        }
        break;

      case "pincode":
        if (!value) break;
        if (!/^\d{6}$/.test(value)) {
          error = "Enter valid 6-digit pincode";
        } else if (!isSubmitting && value !== initialPincode) {
          // Only validate via API if not submitting and pincode has changed
          setValidatingPincode(true);
          try {
            const response = await api.get(`/location/pincode/validate/${value}`);
            if (!response.data.valid) {
              error = "Invalid pincode";
            }
          } catch (err) {
            error = `Failed to validate pincode: ${
              err.response?.data?.message || err.message
            }`;
          } finally {
            setValidatingPincode(false);
          }
        }
        break;

      case "monthly_income":
        if (value && (isNaN(value) || Number(value) < 0)) {
          error = "Monthly income must be a positive number";
        }
        break;

      // Add length validations for address fields
      case "address_line1":
      case "address_line2":
        if (value && value.length > 255) {
          error = "Maximum 255 characters allowed";
        }
        break;

      case "address_area":
      case "address_nearby":
      case "address_district":
      case "address_city":
        if (value && value.length > 100) {
          error = "Maximum 100 characters allowed";
        }
        break;
    }

    return error;
  };

  // Fetch lead data
  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const response = await api.get(`agent/lead/customer/${customerId}`);
        setFormData(response.data.data);
        // Store the initial pincode
        setInitialPincode(response.data.data.pincode);
      } catch (error) {
        setSnackbar({
          open: true,
          message: error.response?.data?.message || "Failed to fetch lead data",
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchLeadData();
  }, [customerId]);

  // Fetch and sort states
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await api.get("/location/countries/IN/states");
        // Sort states alphabetically by name
        const sortedStates = response.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setStates(sortedStates);
      } catch (error) {
        console.error("Failed to fetch states:", error);
      }
    };
    fetchStates();
  }, []);

  // Fetch and sort cities
  useEffect(() => {
    const fetchCities = async () => {
      if (formData.address_state) {
        try {
          const response = await api.get(
            `/location/countries/IN/states/${formData.address_state}/cities`
          );
          // Sort cities alphabetically by name
          const sortedCities = response.data.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setCities(sortedCities);
        } catch (error) {
          console.error("Failed to fetch cities:", error);
        }
      } else {
        setCities([]);
      }
    };
    fetchCities();
  }, [formData.address_state]);

  // Update handleChange to handle async validation
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    const error = await validateField(name, value);
    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  // Update handleSubmit to handle async validation
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields
    const newErrors = {};
    for (const key of Object.keys(formData)) {
      const error = await validateField(key, formData[key], true); // Pass true for isSubmitting
      if (error) newErrors[key] = error;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setSaving(true);
    try {
      // Ensure country code is 'IN' before submitting
      const submitData = {
        ...formData,
        address_country: "IN",
      };

      await api.put(`agent/lead/update/${customerId}`, submitData);
      setSnackbar({
        open: true,
        message: "Lead updated successfully",
        severity: "success",
      });
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      // Handle validation errors from API
      if (error.response?.data?.errors) {
        setErrors(error.response.data.errors);
        setSnackbar({
          open: true,
          message: error.response.data.message || "Validation failed",
          severity: "error",
        });
      } else {
        setSnackbar({
          open: true,
          message: error.response?.data?.message || "Failed to update lead",
          severity: "error",
        });
      }
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Edit Lead Details
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {/* Basic Information */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Basic Information
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                error={!!errors.phone_number}
                helperText={errors.phone_number}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>

            {/* Address Information */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Address Information
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Address Line 1"
                name="address_line1"
                value={formData.address_line1}
                onChange={handleChange}
                error={!!errors.address_line1}
                helperText={errors.address_line1}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Address Line 2"
                name="address_line2"
                value={formData.address_line2}
                onChange={handleChange}
                error={!!errors.address_line2}
                helperText={errors.address_line2}
              />
            </Grid>

            {/* Additional Address Fields */}
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Area"
                name="address_area"
                value={formData.address_area}
                onChange={handleChange}
                error={!!errors.address_area}
                helperText={errors.address_area}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Nearby"
                name="address_nearby"
                value={formData.address_nearby}
                onChange={handleChange}
                error={!!errors.address_nearby}
                helperText={errors.address_nearby}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="District"
                name="address_district"
                value={formData.address_district}
                onChange={handleChange}
                error={!!errors.address_district}
                helperText={errors.address_district}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth error={!!errors.address_city}>
                <InputLabel>City</InputLabel>
                <Select
                  name="address_city"
                  value={formData.address_city}
                  onChange={handleChange}
                  label="City"
                  disabled={!formData.address_state} // Disable if no state selected
                >
                  {cities.map((city) => (
                    <MenuItem key={city.name} value={city.name}>
                      {city.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.address_city && (
                  <FormHelperText>{errors.address_city}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth error={!!errors.address_state}>
                <InputLabel>State</InputLabel>
                <Select
                  name="address_state"
                  value={formData.address_state}
                  onChange={handleChange}
                  label="State"
                >
                  {states.map((state) => (
                    <MenuItem key={state.iso2} value={state.iso2}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.address_state && (
                  <FormHelperText>{errors.address_state}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel>Country</InputLabel>
                <Select
                  name="address_country"
                  value="IN"
                  label="Country"
                  disabled // Country is fixed to India
                >
                  <MenuItem value="IN">India</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Pincode"
                name="pincode"
                value={formData.pincode}
                onChange={handleChange}
                error={!!errors.pincode}
                helperText={errors.pincode}
                InputProps={{
                  endAdornment: validatingPincode && (
                    <CircularProgress size={20} color="inherit" />
                  ),
                }}
              />
            </Grid>

            {/* Additional Information */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Additional Information
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="PAN Number"
                name="pan_number"
                value={formData.pan_number}
                onChange={handleChange}
                error={!!errors.pan_number}
                helperText={errors.pan_number}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Aadhaar Number"
                name="aadhaar_number"
                value={formData.aadhaar_number}
                onChange={handleChange}
                error={!!errors.aadhaar_number}
                helperText={errors.aadhaar_number}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth error={!!errors.occupation}>
                <InputLabel>Occupation</InputLabel>
                <Select
                  name="occupation"
                  value={formData.occupation}
                  onChange={handleChange}
                  label="Occupation"
                >
                  {OCCUPATION_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
                {errors.occupation && (
                  <FormHelperText>{errors.occupation}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Monthly Income"
                name="monthly_income"
                type="number"
                value={formData.monthly_income}
                onChange={handleChange}
                error={!!errors.monthly_income}
                helperText={errors.monthly_income}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.itr_filing_status}>
                <InputLabel>ITR Filing Status</InputLabel>
                <Select
                  name="itr_filing_status"
                  value={formData.itr_filing_status}
                  onChange={handleChange}
                  label="ITR Filing Status"
                >
                  <MenuItem value="Y">Yes</MenuItem>
                  <MenuItem value="N">No</MenuItem>
                </Select>
                {errors.itr_filing_status && (
                  <FormHelperText>{errors.itr_filing_status}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.loan_type}>
                <InputLabel>Loan Type</InputLabel>
                <Select
                  name="loan_type"
                  value={formData.loan_type}
                  onChange={handleChange}
                  label="Loan Type"
                >
                  {LOAN_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
                {errors.loan_type && (
                  <FormHelperText>{errors.loan_type}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* Form Actions */}
            <Grid item xs={12}>
              <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  onClick={() => navigate(-1)}
                  disabled={saving}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={saving}
                  startIcon={saving && <CircularProgress size={20} />}
                >
                  {saving ? "Saving..." : "Save Changes"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditLead;
