import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  TextField,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { Visibility, Edit, Search as SearchIcon } from "@mui/icons-material";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import api from "../../utils/api";
import { useNavigate } from "react-router-dom";

const statusOptions = [
  { value: "A", label: "Pending for Processing" },
  { value: "T", label: "Active" },
  { value: "all", label: "All Status" },
];

const LOAN_TYPE_COLORS = {
  Home: {
    color: "#2196F3",
    backgroundColor: "#E3F2FD",
  },
  Personal: {
    color: "#4CAF50",
    backgroundColor: "#E8F5E9",
  },
  Auto: {
    color: "#FF9800",
    backgroundColor: "#FFF3E0",
  },
  Student: {
    color: "#9C27B0",
    backgroundColor: "#F3E5F5",
  },
  Business: {
    color: "#F44336",
    backgroundColor: "#FFEBEE",
  },
  "Debt Consolidation": {
    color: "#607D8B",
    backgroundColor: "#ECEFF1",
  },
  Agriculture: {
    color: "#795548",
    backgroundColor: "#EFEBE9",
  },
};

const AssignedLeads = () => {
  useDocumentTitle("Assigned Leads");
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("A");
  const [selectedLead, setSelectedLead] = useState(null);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [loadingDetails, setLoadingDetails] = useState(false);
  const navigate = useNavigate();

  // Fetch leads
  const fetchLeads = async () => {
    try {
      setLoading(true);
      const response = await api.get("agent/lead/assigned");
      setLeads(response.data.data || []);
    } catch (error) {
      setSnackbar({
        open: true,
        message:
          error.response?.data?.message || "Failed to fetch assigned leads",
        severity: "error",
      });
      console.error("Error fetching assigned leads:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, []);

  // Filter and search leads
  const filteredLeads = leads.filter((lead) => {
    const matchesStatus =
      statusFilter === "all" || lead.status === statusFilter;
    const matchesSearch =
      lead.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      lead.phone_number.includes(searchQuery) ||
      lead.customer_id.toString().includes(searchQuery);
    return matchesStatus && matchesSearch;
  });

  // Paginated leads
  const paginatedLeads = filteredLeads.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Add this new function to fetch lead details
  const fetchLeadDetails = async (customerId) => {
    try {
      setLoadingDetails(true);
      const response = await api.get(`agent/lead/customer/${customerId}`);
      setSelectedLead(response.data.data);
    } catch (error) {
      setSnackbar({
        open: true,
        message:
          error.response?.data?.message || "Failed to fetch lead details",
        severity: "error",
      });
    } finally {
      setLoadingDetails(false);
    }
  };

  // Add this function to handle edit navigation
  const handleEditLead = (customerId) => {
    navigate(`/agent/edit-lead/${customerId}`);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Assigned Leads
      </Typography>

      {/* Filters and Search */}
      <Paper sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                endAdornment: <SearchIcon color="action" />,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Status Filter</InputLabel>
              <Select
                value={statusFilter}
                label="Status Filter"
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      {/* Leads Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Lead ID</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Assigned Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : paginatedLeads.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No leads found
                </TableCell>
              </TableRow>
            ) : (
              paginatedLeads.map((lead) => (
                <TableRow key={lead.customer_id}>
                  <TableCell>{lead.customer_id}</TableCell>
                  <TableCell>{lead.name}</TableCell>
                  <TableCell>{lead.phone_number}</TableCell>
                  <TableCell>
                    <Chip
                      color={
                        lead.status === "T"
                          ? "success"
                          : lead.status === "A"
                          ? "warning"
                          : "error"
                      }
                      label={
                        lead.status === "T"
                          ? "Active"
                          : lead.status === "A"
                          ? "Pending for Processing"
                          : "Rejected"
                      }
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {new Date(lead.form_submitted_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setOpenDetailDialog(true);
                        fetchLeadDetails(lead.customer_id);
                      }}
                      color="primary"
                      title="View Details"
                    >
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={filteredLeads.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>

      {/* Lead Details Dialog */}
      <Dialog
        open={openDetailDialog}
        onClose={() => {
          setOpenDetailDialog(false);
          setSelectedLead(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Lead Details - {selectedLead?.customer_id}</DialogTitle>
        <DialogContent dividers>
          {loadingDetails ? (
            <Box display="flex" justifyContent="center" p={3}>
              <CircularProgress />
            </Box>
          ) : (
            selectedLead && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    Customer Information
                  </Typography>
                  <Typography>
                    <strong>Name:</strong> {selectedLead.name || "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Contact:</strong>{" "}
                    {selectedLead.phone_number || "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Email:</strong> {selectedLead.email || "N/A"}
                  </Typography>
                  <Typography>
                    <strong>PAN:</strong> {selectedLead.pan_number || "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Aadhaar:</strong>{" "}
                    {selectedLead.aadhaar_number || "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Occupation:</strong>{" "}
                    {selectedLead.occupation || "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Monthly Income:</strong>{" "}
                    {selectedLead.monthly_income
                      ? `₹${selectedLead.monthly_income}`
                      : "N/A"}
                  </Typography>
                  <Typography>
                    <strong>ITR Filing:</strong>{" "}
                    {selectedLead.itr_filing_status === "Y" ? "Yes" : "No"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    Application Information
                  </Typography>
                  <Typography>
                    <strong>Application ID:</strong>{" "}
                    {selectedLead.application_id || "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Status:</strong>{" "}
                    {selectedLead.application_status || "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Loan Type:</strong>{" "}
                    {selectedLead.loan_type ? (
                      <Chip
                        label={selectedLead.loan_type}
                        sx={{
                          color:
                            LOAN_TYPE_COLORS[
                              selectedLead.loan_type.split(" ")[0]
                            ]?.color,
                          backgroundColor:
                            LOAN_TYPE_COLORS[
                              selectedLead.loan_type.split(" ")[0]
                            ]?.backgroundColor,
                          fontWeight: 500,
                          ml: 1,
                        }}
                        size="small"
                      />
                    ) : (
                      <span style={{ marginLeft: "8px" }}>N/A</span>
                    )}
                  </Typography>
                  <Typography>
                    <strong>Created Date:</strong>{" "}
                    {selectedLead.application_created_at
                      ? new Date(
                          selectedLead.application_created_at
                        ).toLocaleString()
                      : "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Assigned Agent:</strong>{" "}
                    {selectedLead.assigned_agent_name || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom>
                    Address Information
                  </Typography>
                  {selectedLead.address_line1 || selectedLead.address_city ? (
                    <Typography>
                      {[
                        selectedLead.address_line1,
                        selectedLead.address_line2,
                        selectedLead.address_area,
                        selectedLead.address_nearby &&
                          `Near ${selectedLead.address_nearby}`,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                      {(selectedLead.address_line1 ||
                        selectedLead.address_line2 ||
                        selectedLead.address_area ||
                        selectedLead.address_nearby) && <br />}
                      {[
                        selectedLead.address_city,
                        selectedLead.address_district,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                      {(selectedLead.address_city ||
                        selectedLead.address_district) && <br />}
                      {[
                        selectedLead.address_state,
                        selectedLead.address_country,
                        selectedLead.pincode && `- ${selectedLead.pincode}`,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </Typography>
                  ) : (
                    <Typography color="text.secondary">
                      Address not available
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDetailDialog(false);
              setSelectedLead(null);
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditLead(selectedLead.customer_id)}
            startIcon={<Edit />}
          >
            Edit Lead
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AssignedLeads;
