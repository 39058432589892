import axios from "axios";

const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add refresh token function
const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");

    if (!refreshToken) {
      throw new Error("No refresh token available");
    }

    const response = await api.post(
      "/agent/login/refresh",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          "Refresh-Token": refreshToken,
        },
      }
    );

    if (response.data.accessToken) {
      localStorage.setItem("accessToken", response.data.accessToken);
      return response.data.accessToken;
    } else {
      throw new Error("No access token received");
    }
  } catch (error) {
    if (!error.config?.url?.includes("login")) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
};

// Keep track of refresh token promise to prevent multiple calls
let refreshTokenPromise = null;

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    const agentId = localStorage.getItem("agentId");

    // Skip auth headers for login and forgot password endpoints
    if (
      config.url.includes("/login") ||
      config.url.includes("/forgot-password")
    ) {
      return config;
    }

    if (!token) {
      window.location.href = "/login";
      return Promise.reject("No access token");
    }

    // Set default headers for all requests
    config.headers["Content-Type"] = "application/json";
    config.headers["JWT-Token"] = token;
    config.headers["Agent-Id"] = agentId;

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const isTokenError = error.response?.data?.code === "TOKEN_EXPIRED";

    if (originalRequest.url === "agent/login") {
      return Promise.reject(error);
    }

    if (isTokenError && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        if (!refreshTokenPromise) {
          refreshTokenPromise = refreshAccessToken();
        }
        const newToken = await refreshTokenPromise;
        refreshTokenPromise = null;

        originalRequest.headers["JWT-Token"] = newToken;
        return api(originalRequest);
      } catch (refreshError) {
        if (!originalRequest.url.includes("login")) {
          window.location.href = "/login";
        }
        return Promise.reject(refreshError);
      }
    }

    // Handle other error cases
    switch (error.response?.status) {
      case 401:
        localStorage.clear();
        window.location.href = "/login";
        break;
      case 403:
        console.error("Access forbidden");
        break;
      case 404:
        console.error("Resource not found");
        break;
      case 500:
        console.error("Server error occurred");
        break;
      default:
        console.error(
          "An error occurred:",
          error.response?.data?.message || error.message
        );
    }

    return Promise.reject(error);
  }
);

export default api;
